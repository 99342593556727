<template>
    <ion-grid>
      <ion-row class="full-height pad-bottom-thirty">
        <ion-col class="margin-bottom-eighty">
          <h3 class="text-center">Notification Assignments</h3>
          <div class="prime-form-container">
            <p>For each notification type, select which user you would like to have notifications automatically assigned to. By default, notifications will be assigned to a patient's full care team.</p>
            <form novalidate="true">
              <div class="prime-form-group" v-for="notification in notifications" :key="notification.id">
                <h6>{{ notification.taskingTypeName }}</h6>
                <select class="form-control" @change="modifyNotificationAssignments(notification.taskingTypeId, $event)">
                  <option value="0" class="text-grey">Patient Care Team Default</option>
                  <option v-for="prof in professionals" :value="prof.professionalId" :key="prof.professionalId + notification.taskingTypeName" :selected="notification.professionalId ? notification.professionalId === prof.professionalId : 0">
                    {{ (prof.title ? prof.title + " " : "") + prof.name }}
                  </option>
                </select>
              </div>
              <button @click="upsertNotifications" type="button" class="prime-button button-primary button-block margin-top-thirty">Save Updates</button>
            </form>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
</template>

<script>
import { send as httpSend } from "@/services/Api";
import PrimePage from "@/components/Global/PrimePage";
import { toastController } from "@ionic/core";

export default {
  name: "NotificationAssignments",
  components: {
    PrimePage
  },
  data() {
    return {
      notifications: [],
      professionals: [],
      notificationAssignments: {},
      pageIsLoading: true,
      errors: []
    };
  },
  async created() {
    await Promise.allSettled([this.fetchOrganizationNotifications(), this.fetchOrganizationProfessionals()]).then(res => {
      this.notifications = res[0].value.data.filter(notif => notif.isManageable);
      this.professionals = res[1].value.data;
      this.notifications.forEach(notification => {
        let notificationObj = {
          [notification["taskingTypeId"]]: notification["professionalId"]
        };
        this.notificationAssignments = { ...this.notificationAssignments, ...notificationObj };
      });
      this.pageIsLoading = false;
    });
  },
  methods: {
    async fetchOrganizationNotifications() {
      this.pageIsLoading = true;
      const method = "get";
      const path = document.config.notificationAssignment + this.$route.params.id;

      try {
        const resp = await httpSend({ path, method, authToken: true });
        return resp;
      } catch (error) {
        toastController
          .create({
            header: "Failed to load organization notifications",
            message: error,
            duration: 7000,
            position: "top"
          })
          .then(m => m.present());
        khanSolo.log(error);
      }
    },
    async fetchOrganizationProfessionals() {
      this.pageIsLoading = true;
      const method = "get";
      const path = document.config.organization + this.$route.params.id + "/professionals";

      try {
        const resp = await httpSend({ path, method, authToken: true });
        return resp;
      } catch (error) {
        this.$ionic.toastController
          .create({
            header: "Failed to load organization professionals",
            message: error,
            duration: 7000,
            position: "top"
          })
          .then(m => m.present());
        khanSolo.log(error);
      }
    },
    modifyNotificationAssignments(notification, e) {
      let newObj = {
        [notification]: e.target.value
      };
      this.notificationAssignments = { ...this.notificationAssignments, ...newObj };
    },
    async upsertNotifications() {
      const method = "post";
      const path = document.config.notificationAssignment + this.$route.params.id;
      const payload = this.notificationAssignments;

      try {
        const resp = await httpSend({ path, method, payload, authToken: true });
        toastController
          .create({
            header: "Success!",
            message: "Notifications have been updated",
            duration: 4000,
            color: "primary",
            mode: "ios"
          })
          .then(m => m.present());
        return resp;
      } catch (error) {
        toastController
          .create({
            header: "Failed to update notifications",
            message: error,
            duration: 7000,
            position: "top"
          })
          .then(m => m.present());
        khanSolo.log(error);
      }
    }
  }
};
</script>

<style scoped>
form {
  margin-bottom: 2rem;
}

.margin-bottom-eighty {
  margin-bottom: 80px;
}
</style>
